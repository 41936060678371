/* GLOBAL + SEMANTIC UI OVERRIDES ------------------------------- */
a {
  color: #db2828;
  text-decoration: underline;
}
a:hover {
  color: #db2828;
  text-decoration: none;
}

/* SPINNER ------------------------------------------------------ */
#spinner {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/* HEADER ------------------------------------------------------- */
#header { }
#header h1 {
  margin: 20px 0;
}

/* FILTERS ------------------------------------------------------ */
#filters {
  margin: 0 0 10px;
}
#filters .ui.button {
  margin: 0 10px 10px 0;
}

/* PROJECT GRID ------------------------------------------------- */
#project-grid { }

/* PROJECT ITEMS ------------------------------------------------ */
.project-item { }
.project-item .thumbnail {
  margin: 0 0 10px;
}
.project-item .thumbnail img {
  display: block;
  max-width: 100%;
}
.project-item h2.title {
  margin: 0 0 15px;
}
.project-item .cats {
  margin: 0 0 10px;
}
.project-item .cats .ui.button {
  margin: 0 5px 5px 0;
}
.project-item .excerpt {
  margin: 0 0 15px;
}
.project-item .ui.button.cta + .ui.button.cta {
  margin-top: 5px;
}
